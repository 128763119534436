import { useUser } from '@auth0/nextjs-auth0/client';
import { useManfredite } from 'hooks/api/manfredite';
import { ManfrediteSettingsAvailabilityStatus } from 'interfaces/manfredite-settings.interface';
import {
  LoginType,
  ManfrediteProfileSummary
} from 'interfaces/manfredite.interface';
import { Offer } from 'interfaces/offer.interface';
import { useRouter } from 'next/router';
import { createContext, useEffect, useState } from 'react';
import ServiceEventTracking from 'services/service.event-tracking';
import CookiesService, {
  COOKIES_NEXT_LOCALE
} from 'services/ui/cookies.service';
import { APP_URLS, getAppUrl } from 'utils/app-urls';

type Attempt = Pick<Offer, 'id' | 'position' | 'slug'> & {
  companyName: string;
};

export type BETA_FEATURES =
  | 'isLinkedinUpdate'
  | 'changePasswordSettings'
  | 'isLinkedinBeta'
  | 'salaryBenchmark'
  | 'offerAlerts'
  | 'techRolesFilter'
  | 'minLanguagesFilter'
  | 'talentPage';

export type LoggedUserContextProps = {
  loadingUser: boolean;
  user: ManfrediteProfileSummary | null;
  error: unknown | null;
  publicProfileActive?: boolean;
  publicProfileSlug: string;
  availabilityStatus: ManfrediteSettingsAvailabilityStatus;
  userHasAttemptFor: (offerId: number) => boolean;
  refreshUserData: (attrs?: {
    attempt?: Attempt | null;
    offerId?: number;
    name?: string;
    lastName?: string;
    avatarUrl?: string;
    availabilityStatus?: ManfrediteSettingsAvailabilityStatus;
    publicProfileActive?: boolean;
    publicProfileSlug?: string;
    preferredLanguage?: string;
  }) => Promise<void>;
  clearUserData: () => void;
  logoutUser: (locale?: string, returnUrl?: string) => void;
};

export type RefreshUserDataArgs = Parameters<
  LoggedUserContextProps['refreshUserData']
>[0];

export const LoggedUserContext = createContext<LoggedUserContextProps>({
  loadingUser: false,
  user: null,
  error: null,
  availabilityStatus: 'ACTIVE',
  publicProfileSlug: '',
  refreshUserData: async () => {
    // NOOP
  },
  clearUserData: () => {
    // NOOP
  },
  userHasAttemptFor: () => {
    return false;
  },
  logoutUser: () => {
    // NOOP
  }
});

export const Auth0AuthTypes = [
  'auth0',
  'google-oauth2',
  'github',
  'linkedin'
] as const;
export type Auth0AuthType = typeof Auth0AuthTypes[number];

const LoginTypeAuth0Dict: Record<Auth0AuthType, LoginType> = {
  auth0: 'user_pass',
  'google-oauth2': 'google',
  github: 'github',
  linkedin: 'linkedin'
};

type LoginTypeText = {
  url: string;
  name: string;
};

export const LoginTypeTextDict: Record<LoginType, LoginTypeText> = {
  user_pass: {
    url: '',
    name: ''
  },
  github: {
    url: 'https://github.com',
    name: 'GitHub'
  },

  google: {
    url: 'https://google.com',
    name: 'Google'
  },

  linkedin: {
    url: 'https://linkedin.com',
    name: 'LinkedIn'
  }
};

export function getLoginTypeFromSub(
  sub?: string | null
): LoginType | undefined {
  const subType = sub?.split('|')[0] as Auth0AuthType;

  return subType ? LoginTypeAuth0Dict[subType] : undefined;
}

const LoggedUserProvider: React.FC = ({ children }) => {
  const { asPath, locale } = useRouter();

  const { manfredite } = useManfredite();
  const { user: auth0User, isLoading } = useUser();
  const [loadingUser, setLoadingUser] = useState(true);
  const [user, setUser] = useState<ManfrediteProfileSummary | null>(null);
  const [error, setError] = useState<unknown | null>(null);

  function setUserFromAuth0(): void {
    setUser({
      id: 42,
      email: auth0User?.email || '',
      name: auth0User?.name || '',
      lastName: '',
      preferredLanguage: 'EN',
      loginType: getLoginTypeFromSub(auth0User?.sub)
    });
  }

  async function fetchUserData(): Promise<void> {
    try {
      if (!manfredite) return;

      const profileSummary: ManfrediteProfileSummary = {
        legacyManfredite: manfredite.isLegacy,
        name: manfredite.firstName || '',
        lastName: manfredite.lastName || '',
        id: manfredite.manfrediteId,
        email: manfredite.email,
        avatarUrl: manfredite.avatar?.url,
        configuration: {
          availabilityStatus: manfredite.availabilityStatus,
          publicProfileActive: manfredite.profilePreferences.isPublic,
          publicProfileSlug: manfredite.profilePreferences.slug
        },
        preferredLanguage: manfredite.preferredLanguage,

        loginType: getLoginTypeFromSub(auth0User?.sub),
        offers: {
          applications: manfredite.offers?.applications || [],
          attempt: manfredite.offers?.applicationAttempt
            ? {
                offerId: manfredite.offers.applicationAttempt.offerId,
                position: {
                  ES: manfredite.offers.applicationAttempt.position,
                  EN: manfredite.offers.applicationAttempt.position
                },
                slug: {
                  ES: manfredite.offers.applicationAttempt.slug,
                  EN: manfredite.offers.applicationAttempt.slug
                },
                companyName: manfredite.offers.applicationAttempt.companyName
              }
            : undefined
        }
      };

      setUser(profileSummary);

      ServiceEventTracking.identifyUser(profileSummary);

      setError(null);
      setLoadingUser(false);

      const preferredLang = manfredite.preferredLanguage.toLowerCase();

      // Explicitly set selected app language according to the user preferred lang
      CookiesService.add(COOKIES_NEXT_LOCALE, preferredLang);

      // If current app lang is not the same as the preferred lang
      // (I was in "es" but my preferred lang is "en") => redirect to the preferred lang
      if (locale !== preferredLang) {
        const targetPath = `/${preferredLang}${asPath}`;

        window.location.href = `${window.location.origin}${targetPath}`;
      }
    } catch (e) {
      console.error(`Error while fetching logged user data: ${e}`);

      setLoadingUser(false);
      setError(e);
      setUserFromAuth0();
    }
  }

  function getStringValueOrDefault(
    str: string | undefined,
    defaultValue: string
  ): string {
    return str === '' ? '' : str ?? defaultValue;
  }

  useEffect(() => {
    (async () => {
      if (auth0User && !isLoading) {
        if (!user && !!manfredite) {
          fetchUserData();
        } else {
          setLoadingUser(false);
          setError(null);
        }
      }

      if (!auth0User && !isLoading) {
        setLoadingUser(false);
      }
    })();
  }, [auth0User, manfredite, isLoading]);

  return (
    <LoggedUserContext.Provider
      value={{
        user,
        loadingUser,
        error,
        availabilityStatus: user?.configuration?.availabilityStatus || 'ACTIVE',
        publicProfileActive: user?.configuration?.publicProfileActive || false,
        publicProfileSlug: user?.configuration?.publicProfileSlug || '',

        refreshUserData: async (attrs): Promise<void> => {
          if (attrs && user) {
            let attempt = user.offers?.attempt;
            let applications = user.offers?.applications;

            if (attrs.attempt) {
              attempt = {
                offerId: attrs.attempt.id,
                position: attrs.attempt.position,
                slug: attrs.attempt.slug,
                companyName: attrs.attempt.companyName
              };
            } else if (attrs.attempt === null) {
              // Remove attempt
              attempt = undefined;
            }

            if (attrs.offerId) {
              applications = user.offers?.applications
                ? [...new Set([...user.offers.applications, attrs.offerId])]
                : [attrs.offerId];
            }

            setUser({
              ...user,
              configuration: {
                ...user.configuration,
                availabilityStatus:
                  attrs.availabilityStatus ||
                  user.configuration?.availabilityStatus,
                publicProfileActive:
                  attrs.publicProfileActive ??
                  user.configuration?.publicProfileActive,
                publicProfileSlug:
                  attrs.publicProfileSlug ||
                  user.configuration?.publicProfileSlug
              },
              preferredLanguage:
                attrs.preferredLanguage || user.preferredLanguage,
              name: getStringValueOrDefault(attrs.name, user.name),
              lastName: getStringValueOrDefault(attrs.lastName, user.lastName),
              avatarUrl: getStringValueOrDefault(
                attrs.avatarUrl,
                user.avatarUrl || ''
              ),
              offers: {
                ...user.offers,
                attempt,
                applications
              }
            });

            return;
          }

          await fetchUserData();
        },

        userHasAttemptFor: (offerId: number): boolean => {
          return user?.offers?.attempt?.offerId === offerId;
        },

        clearUserData: (): void => {
          setUser(null);
        },

        logoutUser: (locale?: string, returnUrl?: string): void => {
          setUser(null);

          window.location.replace(
            `${window.location.origin}${getAppUrl(
              APP_URLS.logout,
              locale
            )}?returnUrl=${returnUrl || ''}`
          );
        }
      }}
    >
      {children}
    </LoggedUserContext.Provider>
  );
};
export default LoggedUserProvider;
