import { z } from 'zod';
import { FactSchema } from './model.fact';
import { LinksSchema } from './model.links';
import { LocationSchema } from './model.location';
import { MediaSummarySchema } from './model.media-summary';
import { ProfilePreferencesSchema } from './model.profile-preferences';
import { SalarySchema } from './model.salary';
import { SoftSkillV2Schema } from './model.soft-skill';
import { TechSchema } from './model.tech';
import { TechRoleSchema } from './model.tech-role';
import { TechSummarySchema } from './model.tech-summary';
import { WorkplacePreferencesSchema } from './model.workplace-preferences';
import { YearsOfExperienceSchema } from './model.years-of-experience';
import { TagSchema } from './tag';

export const ManfrediteSchema = z.object({
  manfrediteId: z.number(),
  email: z.string(),
  firstName: z.string().max(255).optional(),
  lastName: z.string().max(255).optional(),
  whereDoYouLive: LocationSchema.optional(),
  telephone: z.string().optional(),
  headline: z.string().optional(),
  introduction: z.string().optional(),
  softSkills: z.array(SoftSkillV2Schema),
  goodPractices: z.string().optional(),
  facts: z.array(FactSchema),
  links: LinksSchema,
  yearsOfExperience: YearsOfExperienceSchema.optional(),
  desiredRoles: z.array(TechRoleSchema),
  desiredSalary: SalarySchema.optional(),
  desiredSalaryDescription: z.string().optional(),
  currentSalary: z.number().optional(),
  desiredJobDescription: z.string().optional(),
  likedTechs: z.array(TechSummarySchema),
  dislikedTechs: z.array(TechSummarySchema),
  workplacePreferences: WorkplacePreferencesSchema,
  profilePreferences: ProfilePreferencesSchema,
  cvFile: MediaSummarySchema.optional(),
  avatar: MediaSummarySchema.optional(),

  availabilityStatus: z.enum(['ACTIVE', 'OPEN', 'NOT AVAILABLE']),
  preferredLanguage: z.enum(['ES', 'EN']),
  isNewsletterActive: z.boolean(),
  hasOnboardingComplete: z.boolean(),
  isInterestedOnWorkAtManfred: z.boolean(),
  techs: z.array(TechSchema).optional(),
  testingTags: z.array(TagSchema).optional(),
  hasSomeDevRole: z.boolean(),
  contractTypes: z.array(z.enum(['EMPLOYED', 'SELF_EMPLOYED'])),
  isLegacy: z.boolean(),
  offers: z.object({
    applications: z.array(z.number()),
    applicationAttempt: z
      .object({
        offerId: z.number(),
        position: z.string(),
        slug: z.string(),
        companyName: z.string()
      })
      .optional()
  })
});

export type Manfredite = z.infer<typeof ManfrediteSchema>;
