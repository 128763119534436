/**
 * Remove all HTML tags from given `text`
 *
 * @param text - text to be stripped from HTML tags
 * @returns The text without HTML tags
 */
export function stripHtml(text: string): string {
  return decodeHTMLEntities(text?.replace(/(<([^>]+)>)/gi, ''));
}

function decodeHTMLEntities(text: string): string {
  const entities = [
    ['amp', '&'],
    ['apos', "'"],
    ['#x27', "'"],
    ['#x2F', '/'],
    ['#39', "'"],
    ['#47', '/'],
    ['lt', '<'],
    ['gt', '>'],
    ['nbsp', ' '],
    ['quot', '"']
  ];

  for (let i = 0, max = entities.length; i < max; ++i)
    text = text?.replace(
      new RegExp('&' + entities[i][0] + ';', 'g'),
      entities[i][1]
    );

  return text;
}
