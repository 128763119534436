export enum OFFERS_STATUS {
  DRAFT = 'DRAFT',
  FINISHED = 'FINISHED',
  CLOSED = 'CLOSED',
  PAUSED = 'PAUSED',
  PUBLISHED = 'PUBLISHED',
  REACTIVATED = 'REACTIVATED',
  ACTIVE = 'ACTIVE'
}

export enum OFFER_TECH_SKILLS_SECTION {
  MUST = 'MUST',
  COULD = 'COULD',
  EXTRA = 'EXTRA'
}

export enum OFFER_SOFT_SKILLS_SECTION {
  MUST = 'MUST',
  COULD = 'COULD',
  EXTRA = 'EXTRA'
}

export enum OFFER_TECH_SKILLS_LEVEL {
  ADVANCED = 'ADVANCED',
  INTERMEDIATE = 'INTERMEDIATE',
  BASIC = 'BASIC'
}

export const OFFER_STATUS_CLOSED = [
  OFFERS_STATUS.FINISHED.toString(),
  OFFERS_STATUS.CLOSED.toString()
];
