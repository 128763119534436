import { useLocalStorage } from '@asgard/ui-common';
import { usePendingFieldsForOfferApplying } from 'features/portal/v2/components/layout/PendingFieldsForOfferApplying/PendingFieldsForOfferApplying.hooks';
import OfferApplyModal from 'features/portal/v2/components/OfferApplyModal/OfferApplyModal';
import { WebOffer } from 'model/model.web-offer';
import { withTranslation, WithTranslation } from 'next-i18next';
import { createContext, useEffect, useState } from 'react';
import { useSessionStorage } from '../../components/hooks/useSessionStorage';
import {
  ALREADY_SHOWN_MISSING_DATA_MODAL_PREFIX,
  ALREADY_SHOWN_MISSING_DATA_MODAL_SESSION_PREFIX
} from '../../components/shared/offerapplymodal.context.constants';

type OfferApplyModalContextProps = {
  openOfferAplyModal: (opts: {
    offerId?: number;
    offer?: WebOffer;
    missingDataToApply?: boolean;
  }) => void;
  alreadyShowMissingDataModal?: boolean;
  alreadyShowInSessionMissingDataModal?: boolean;
};

export const OfferApplyModalContext =
  createContext<OfferApplyModalContextProps>({
    openOfferAplyModal: () => {
      // NOOP
    }
  });

type OfferApplyModalProviderProps = WithTranslation;

const OfferApplyModalProvider: React.FC<OfferApplyModalProviderProps> = ({
  i18n,
  children
}) => {
  const [applyModalIsOpen, setApplyModalIsOpen] = useState(false);
  const [offerId, setOfferId] = useState<number>();
  const [offer, setOffer] = useState<WebOffer>();
  const [missingDataToApply, setMissingDataToApply] = useState(false);

  const { applicationAttempt } = usePendingFieldsForOfferApplying();

  const LOCAL_STORAGE_KEY = `${ALREADY_SHOWN_MISSING_DATA_MODAL_PREFIX}${offerId}`;
  const SESSION_STORAGE_KEY = `${ALREADY_SHOWN_MISSING_DATA_MODAL_SESSION_PREFIX}${offerId}`;

  const [alreadyShowMissingDataModal, setAlreadyShowMissingDataModal] =
    useLocalStorage(LOCAL_STORAGE_KEY, false);

  const [
    alreadyShowInSessionMissingDataModal,
    setAlreadyShowInSessionMissingDataModal
  ] = useSessionStorage(SESSION_STORAGE_KEY, false);

  function openApplyModal(): void {
    setApplyModalIsOpen(true);
  }

  function closeApplyModal(): void {
    setApplyModalIsOpen(false);
  }

  useEffect(() => {
    if (alreadyShowInSessionMissingDataModal) {
      closeApplyModal();
    }
  }, [alreadyShowInSessionMissingDataModal]);

  return (
    <OfferApplyModalContext.Provider
      value={{
        alreadyShowMissingDataModal: alreadyShowMissingDataModal,
        alreadyShowInSessionMissingDataModal:
          alreadyShowInSessionMissingDataModal,

        openOfferAplyModal: ({ offerId, offer, missingDataToApply }) => {
          if (!offerId) {
            return;
          }

          setOfferId(offerId);
          setOffer(offer);
          openApplyModal();
          setMissingDataToApply(!!missingDataToApply);
        }
      }}
    >
      {children}

      {applyModalIsOpen && offerId && (
        <OfferApplyModal
          applicationAttempt={applicationAttempt}
          missingDataToApply={missingDataToApply}
          applyModalIsOpen={applyModalIsOpen}
          openApplyModal={openApplyModal}
          applyModalClose={closeApplyModal}
          offer={offer}
          offerId={offerId}
          i18n={i18n}
          setAlreadyShowMissingDataModal={setAlreadyShowMissingDataModal}
          alreadyShowMissingDataModal={alreadyShowMissingDataModal}
          setAlreadyShowInSessionMissingDataModal={
            setAlreadyShowInSessionMissingDataModal
          }
          alreadyShowInSessionMissingDataModal={
            !!alreadyShowInSessionMissingDataModal
          }
        />
      )}
    </OfferApplyModalContext.Provider>
  );
};
export default withTranslation(['job-offer', 'apply-modal'])(
  OfferApplyModalProvider
);
