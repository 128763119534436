module.exports = {
  home: {
    path: '/',
    langs: {
      es: '/',
      en: '/'
    },
    params: ''
  },

  notFound: {
    path: '/404',
    langs: {
      es: '/404',
      en: '/404'
    },
    params: ''
  },

  bringYourOwnTeam: {
    path: '/bring-your-own-team',
    langs: {
      es: '/ready-made-teams',
      en: '/ready-made-teams'
    },
    params: ''
  },

  magnetsOnboarding: {
    path: '/magnets-onboarding',
    langs: {
      es: '/magnets-onboarding',
      en: '/magnets-onboarding'
    },
    params: ''
  },

  magnetsIA: {
    path: '/magnets-ia',
    langs: {
      es: '/resource-ia',
      en: '/resource-ia'
    },
    params: ''
  },

  stackoverflowgeddon: {
    path: '/stackoverflowgeddon',
    langs: {
      es: '/stackoverflowgeddon',
      en: '/stackoverflowgeddon'
    },
    params: ''
  },

  cvApplicationNoPermission: {
    path: '/application-no-permission',
    langs: {
      es: '/candidatura-no-accesible',
      en: '/application-no-permission'
    },
    params: ''
  },

  join: {
    path: '/api/auth/signup',
    langs: {
      es: '/unete',
      en: '/signup'
    },
    params: ''
  },

  joinWithSource: {
    path: '/api/auth/signup?source=:source',
    langs: {
      es: '/unete',
      en: '/signup'
    },
    params: '/:source'
  },

  joinUs: {
    path: '/join-us',
    langs: {
      es: '/unete-a-manfred',
      en: '/join-us'
    },
    params: ''
  },

  login: {
    path: '/api/auth/login',
    langs: {
      es: '/entra',
      en: '/signin'
    },
    params: ''
  },

  logout: {
    path: '/api/auth/logout',
    langs: {
      es: '/logout',
      en: '/logout'
    },
    params: ''
  },

  cvApplicationsLanding: {
    path: '/applications/landing',
    langs: {
      es: '/tuscandidaturas',
      en: '/yourjobapplications'
    },
    params: ''
  },

  portalV2Dashboard: {
    path: '/portal/v2',
    langs: {
      es: '/portal',
      en: '/portal'
    },
    params: ''
  },

  portalV2Introduction: {
    path: '/portal/v2/introduction',
    langs: {
      es: '/portal/presentacion',
      en: '/portal/introduction'
    },
    params: ''
  },

  portalV2Contact: {
    path: '/portal/v2/contact',
    langs: {
      es: '/portal/contacto',
      en: '/portal/contact'
    },
    params: ''
  },

  portalV2ContactEmailUpdate: {
    path: '/portal/v2/contact/email-update',
    langs: {
      es: '/portal/contacto/email-update',
      en: '/portal/contact/email-update'
    },
    params: ''
  },

  portalV2Experience: {
    path: '/portal/v2/experience',
    langs: {
      es: '/portal/experiencia-laboral',
      en: '/portal/experience'
    },
    params: ''
  },

  portalV2KnowledgeAndEducation: {
    path: '/portal/v2/knowledge-and-education',
    langs: {
      es: '/portal/conocimientos-y-educacion',
      en: '/portal/knowledge-and-education'
    },
    params: ''
  },

  portalV2Languages: {
    path: '/portal/v2/languages',
    langs: {
      es: '/portal/idiomas',
      en: '/portal/languages'
    },
    params: ''
  },

  portalV2JobHunt: {
    path: '/portal/v2/job-hunt',
    langs: {
      es: '/portal/empleo',
      en: '/portal/job'
    },
    params: ''
  },

  portalV2DownloadProfile: {
    path: '/portal/v2/download-profile',
    langs: {
      es: '/portal/descargar-perfil',
      en: '/portal/download-profile'
    },
    params: ''
  },

  portalV2ThirdPartyApplications: {
    path: '/portal/v2/third-party-job-applications',
    langs: {
      es: '/portal/candidaturas',
      en: '/portal/job-applications'
    },
    params: ''
  },

  portalV2SalaryBenchmark: {
    path: '/portal/v2/salary-benchmark',
    langs: {
      es: '/portal/salary-benchmark',
      en: '/portal/salary-benchmark'
    },
    params: ''
  },

  portalV2PrivacyAndProfile: {
    path: '/portal/v2/privacy-and-profile',
    langs: {
      es: '/portal/privacidad-y-perfil',
      en: '/portal/privacy-and-profile'
    },
    params: ''
  },

  portalV2Settings: {
    path: '/portal/v2/settings',
    langs: {
      es: '/portal/ajustes',
      en: '/portal/settings'
    },
    params: ''
  },

  manfrediteProfileV2: {
    path: '/profile-v2',
    langs: {
      es: '/perfil',
      en: '/profile'
    },
    params: '/:path*'
  },

  profilePreview: {
    path: '/profile-preview',
    langs: {
      es: '/profile-preview',
      en: '/profile-preview'
    },
    params: '/:path*'
  },

  messagesInfoPrivateBeta: {
    path: '/info/private-beta',
    langs: {
      es: '/info/beta-privada',
      en: '/info/private-beta'
    },
    params: ''
  },

  messagesInfoEmailVerification: {
    path: '/info/email-verification',
    langs: {
      es: '/info/verificar-email',
      en: '/info/email-verification'
    },
    params: ''
  },

  messagesInfoError: {
    path: '/info/error',
    langs: {
      es: '/info/error',
      en: '/info/error'
    },
    params: ''
  },

  messagesInfoDeleteAccount: {
    path: '/info/delete-account',
    langs: {
      es: '/info/borrar-cuenta',
      en: '/info/delete-account'
    },
    params: ''
  },

  termsOfUse: {
    path: '/terms-of-use',
    langs: {
      es: '/terminos-de-uso',
      en: '/terms-of-use'
    },
    params: ''
  },

  manfredDaily: {
    path: '/manfred-daily',
    langs: {
      es: '/manfred-daily',
      en: '/manfred-daily'
    },
    params: ''
  },

  manfredDailyCompanies: {
    path: '/manfred-daily-companies',
    langs: {
      es: '/manfred-daily-empresas',
      en: '/manfred-daily-companies'
    },
    params: ''
  },

  beManfredite: {
    path: '/manfredite',
    langs: {
      es: '/ser-manfredita',
      en: '/be-manfredite'
    },
    params: ''
  },

  talent: {
    path: '/talent',
    langs: {
      es: '/talento',
      en: '/talent'
    },
    params: ''
  },

  privacyPolicy: {
    path: '/privacy-policy',
    langs: {
      es: '/politica-de-privacidad',
      en: '/privacy-policy'
    },
    params: ''
  },

  cookies: {
    path: '/cookies',
    langs: {
      es: '/cookies',
      en: '/cookies'
    },
    params: ''
  },

  stackOverflowgeddon: {
    path: '/stackoverflowgeddon',
    langs: {
      es: '/stackoverflowgeddon',
      en: '/stackoverflowgeddon'
    },
    params: ''
  },

  companies: {
    path: '/companies',
    langs: {
      es: '/empresas',
      en: '/companies'
    },
    params: ''
  },

  companiesForm: {
    path: '/companies-form',
    langs: {
      es: '/empresas-form',
      en: '/companies-form'
    },
    params: ''
  },

  companies2: {
    path: '/companies2',
    langs: {
      es: '/empresas2',
      en: '/companies2'
    },
    params: ''
  },

  team: {
    path: '/team',
    langs: {
      es: '/nosotros',
      en: '/team'
    },
    params: ''
  },

  jobOffers: {
    path: '/job-offers',
    langs: {
      es: '/ofertas-empleo',
      en: '/job-offers'
    },
    params: ''
  },

  jobOffer: {
    path: '/job-offers',
    langs: {
      es: '/ofertas-empleo',
      en: '/job-offers'
    },
    params: '/:path*'
  },

  messages: {
    path: '/messages',
    langs: {
      es: '/info',
      en: '/info'
    },
    params: '/:path*'
  },

  roles: {
    path: '/roles',
    langs: {
      es: '/roles-profesionales',
      en: '/professional-roles'
    },
    params: ''
  },

  launchLanding: {
    path: '/launch-landing',
    langs: {
      es: '/lanzamos-el-portal-de-manfreditas',
      en: '/manfredites-portal-launch'
    },
    params: ''
  },

  blog: {
    path: '/blog',
    langs: {
      es: '/blog',
      en: '/blog'
    },
    params: ''
  },

  mandecar22: {
    path: '/developer-career-report-2022',
    langs: {
      es: '/developer-career-report-2022',
      en: '/developer-career-report-2022'
    },
    params: ''
  },

  mandecar23: {
    path: '/developer-career-report-2023',
    langs: {
      es: '/developer-career-report-2023',
      en: '/developer-career-report-2023'
    },
    params: ''
  },

  mantechcar24: {
    path: '/developer-career-report-2024',
    langs: {
      es: '/developer-career-report',
      en: '/developer-career-report'
    },
    params: ''
  },

  fredcon24: {
    path: '/fredcon24',
    langs: {
      es: '/fredcon24',
      en: '/fredcon24'
    },
    params: ''
  },

  hiringProcessComparison: {
    path: '/hiring-process-comparison',
    langs: {
      es: '/comparador-procesos-seleccion',
      en: '/recruitment-process-comparator'
    },
    params: ''
  },

  salaryBenchmark: {
    path: '/portal/salary-benchmark',
    langs: {
      es: '/portal/salary-benchmark',
      en: '/portal/salary-benchmark'
    },
    params: ''
  },

  salaryCompass: {
    path: '/salary-compass',
    langs: {
      es: '/brujula-salarial',
      en: '/salary-compass'
    },
    params: ''
  },

  memberGetMember: {
    path: '/member-get-member',
    langs: {
      es: '/member-get-member',
      en: '/member-get-member'
    },
    params: ''
  },

  theDavidTest: {
    path: '/the-david-test',
    langs: {
      es: '/the-david-test',
      en: '/the-david-test'
    },
    params: ''
  },

  outOfOffice: {
    path: '/out-of-office',
    langs: {
      es: '/out-of-office',
      en: '/out-of-office'
    },
    params: ''
  },

  outOfOfficeOk: {
    path: '/out-of-office-ok',
    langs: {
      es: '/out-of-office-suscripcion-completada',
      en: '/out-of-office-subscription-ok'
    },
    params: ''
  },

  helpingJuniors: {
    path: '/helping-juniors',
    langs: {
      es: '/helping-juniors',
      en: '/helping-juniors'
    },
    params: ''
  },

  helpingCompanies: {
    path: '/helping-companies',
    langs: {
      es: '/helping-companies',
      en: '/helping-companies'
    },
    params: ''
  },

  hrAsAService: {
    path: '/hr-as-a-service',
    langs: {
      es: '/hr-as-a-service',
      en: '/hr-as-a-service'
    },
    params: ''
  },

  cvApplicationsView: {
    path: '/applications',
    langs: {
      es: '/candidaturas',
      en: '/applications'
    },
    params: '/:path*'
  },

  talentEngineLanding: {
    path: '/talent-engine',
    langs: {
      es: '/talent-agency',
      en: '/talent-agency'
    },
    params: ''
  },

  talentEngineSave: {
    path: '/talent-engine/save',
    langs: {
      es: '/talent-agency/ahorra-tiempo-y-dinero',
      en: '/talent-agency/save-time-and-money'
    },
    params: ''
  },

  talentEngineCandidates: {
    path: '/talent-engine/candidates',
    langs: {
      es: '/talent-agency/mejores-candidatos',
      en: '/talent-agency/better-candidates'
    },
    params: ''
  },

  talentEngineHiring: {
    path: '/talent-engine/hiring',
    langs: {
      es: '/talent-agency/contrata-mas-y-mas-rapido',
      en: '/talent-agency/better-hiring'
    },
    params: ''
  },

  talentEngineInterviews: {
    path: '/talent-engine/interviews',
    langs: {
      es: '/talent-agency/consigue-buenas-entrevistas',
      en: '/talent-agency/better-interviews'
    },
    params: ''
  },

  talentEngineBlog: {
    path: '/talent-engine/blog',
    langs: {
      es: '/talent-agency/blog',
      en: '/talent-agency/blog'
    },
    params: ''
  },

  talentEngineBlogPost: {
    path: '/talent-engine/blog/:slug',
    langs: {
      es: '/talent-agency/blog/:slug',
      en: '/talent-agency/blog/:slug'
    },
    params: ''
  },

  talentEngineBlogPages: {
    path: '/talent-engine/blog/page/:page',
    langs: {
      es: '/talent-agency/blog/page/:page',
      en: '/talent-agency/blog/page/:page'
    },
    params: ''
  },

  talentEngineBlogWhatsNew: {
    path: '/talent-engine/blog/whatsnew',
    langs: {
      es: '/talent-agency/blog/whatsnew',
      en: '/talent-agency/blog/whatsnew'
    },
    params: ''
  },

  battleReport: {
    path: '/battle-report',
    langs: {
      es: '/parte-de-guerra',
      en: '/battle-report'
    },
    params: ''
  },

  stateOfHiring: {
    path: '/state-of-hiring',
    langs: {
      es: '/hiring-report',
      en: '/hiring-report'
    },
    params: ''
  },

  consultancy: {
    path: '/consultancy',
    langs: {
      es: '/consultancy',
      en: '/consultancy'
    },
    params: ''
  },

  circularToManfred: {
    path: '/circular-to-manfred',
    langs: {
      es: '/circular-to-manfred',
      en: '/circular-to-manfred'
    },
    params: ''
  }
};
