import { MultilingualLanguages } from '@asgard/api-common';
import Modal from 'components/shared/Modal';
import { ALREADY_SHOWN_MISSING_DATA_MODAL_PREFIX } from 'components/shared/offerapplymodal.context.constants';
import Spinner from 'components/shared/Spinner';
import { LoggedUserContext } from 'context/logged-user/loggeduser.context';
import { ManfrediteFieldsStatusContext } from 'context/manfredite-fields-status';
import { useI18n } from 'hooks/useI18n';
import { useToasts } from 'hooks/useToasts/useToasts';
import { UIOfferData } from 'interfaces/ui/ui-offer-data';
import { withTranslation } from 'next-i18next';
import { useRouter } from 'next/dist/client/router';
import { useContext, useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import OfferApplicationService from 'services/offer-application.service';
import ServiceEventTracking from 'services/service.event-tracking';
import { ServiceWebOffer } from 'services/service.web-offer';
import { APP_URLS, getAppUrl } from 'utils/app-urls';
import { getErrorMessage } from 'utils/errors';
import { StyledContainer } from './OfferApplyModal.styled';
import { OfferApplyModalProps } from './OfferApplyModal.types';
import OfferApplyModalAplicationComplete from './OfferApplyModalAplicationComplete';
import OfferApplyModalPositionClose from './OfferApplyModalPositionClose';
import OfferApplyModalPositionCloseMissingData from './OfferApplyModalPositionCloseMissingData';
import OfferApplyModalPositionNonExistent from './OfferApplyModalPositionNonExistent';
import OfferApplyModalPositionOpenMissingData from './OfferApplyModalPositionOpenMissingData';

const OfferApplyModal: React.FC<OfferApplyModalProps> = ({
  applyModalIsOpen,
  applyModalClose,
  offer,
  offerId,
  applicationAttempt,
  openApplyModal,
  setAlreadyShowMissingDataModal,
  alreadyShowMissingDataModal,
  setAlreadyShowInSessionMissingDataModal,
  alreadyShowInSessionMissingDataModal
}) => {
  const { t } = useI18n('apply-modal');
  const router = useRouter();

  const { user, userHasAttemptFor, refreshUserData } =
    useContext(LoggedUserContext);

  const { addErrorToast } = useToasts();
  const { userCanApplyToOffers } = useContext(ManfrediteFieldsStatusContext);

  const canDeleteAttempt = useRef<boolean>(true);
  const [isSubmittingYes, setIsSubmittingYes] = useState(false);
  const [isSubmittingNo, setIsSubmittingNo] = useState(false);
  const [uiOffer, setUIOffer] = useState<UIOfferData | null>(
    offer ? offer : null
  );

  const [isLoading, setIsLoading] = useState(!offer);

  const userHasAppliedToOffer = user?.offers?.applications?.includes(offerId);

  const userCanApplyToCurrentOffer =
    !uiOffer?.heroSectionData.closed && !userHasAppliedToOffer;

  if (applicationAttempt) {
    openApplyModal();
  }

  const handleYesClick = async (): Promise<void> => {
    try {
      canDeleteAttempt.current = false;

      setIsSubmittingYes(true);

      await OfferApplicationService.applyToOffer(offerId);

      window.localStorage.removeItem(
        `${ALREADY_SHOWN_MISSING_DATA_MODAL_PREFIX}${offerId}`
      );

      ServiceEventTracking.track({
        type: 'Offers - Offer Applied',
        properties: {
          status: uiOffer?.status,
          offerCompany: uiOffer?.footerData.company,
          offerName: uiOffer?.footerData.internalCode,
          offerURL: `${window.location.origin}/${router.locale}${getAppUrl(
            APP_URLS.jobOffer,
            router.locale
          )}/${offerId}`
        }
      });

      await router.push(
        `${getAppUrl(APP_URLS.jobOffer, router.locale)}/${offerId}/applied`
      );

      refreshUserData({
        attempt: null,
        offerId
      });

      applyModalClose();
    } catch (error) {
      console.error(`Could not apply to offer ${offerId}. Exception: ${error}`);

      setIsSubmittingYes(false);
      addErrorToast(
        t('messages.error.apply', {
          error: getErrorMessage(error)
        })
      );
    }
  };

  const handleNoClick = async (): Promise<void> => {
    try {
      canDeleteAttempt.current = false;
      setIsSubmittingNo(true);

      if (userHasAttemptFor(offerId)) {
        await OfferApplicationService.dismissApplicationAttempt(offerId);

        refreshUserData({
          attempt: null
        });
      }

      window.localStorage.removeItem(
        `${ALREADY_SHOWN_MISSING_DATA_MODAL_PREFIX}${offerId}`
      );

      setIsSubmittingNo(false);
      applyModalClose();
    } catch (error) {
      console.warn(
        `Could not dismiss Job offer application. Exception: ${error}`
      );
      setIsSubmittingNo(false);
    }
  };

  function getModalTitle(): string {
    if (!uiOffer && isLoading) {
      return '';
    }
    if (!uiOffer && !isLoading) {
      return t('offer-not-exist.title');
    }

    if (uiOffer && !isLoading) {
      if (
        userCanApplyToCurrentOffer &&
        !userCanApplyToOffers &&
        applicationAttempt &&
        !alreadyShowMissingDataModal
      ) {
        return t('missing-data.title');
      }

      if (
        userCanApplyToCurrentOffer &&
        !userCanApplyToOffers &&
        applicationAttempt &&
        alreadyShowMissingDataModal
      ) {
        return t('missing-data-round2.title');
      }

      if (userHasAppliedToOffer) {
        return t('title.userHasApplied');
      }
      if (!userCanApplyToCurrentOffer && userCanApplyToOffers) {
        return t('offer-close.title');
      }

      if (!userCanApplyToCurrentOffer && !userCanApplyToOffers) {
        return t('offer-close-missing-data.title');
      }
    }

    return t('title.default');
  }

  useEffect(() => {
    (async () => {
      if (!offer && offerId) {
        try {
          const data = await ServiceWebOffer.getById(
            offerId,
            router.locale?.toUpperCase() as MultilingualLanguages
          );

          setUIOffer(data);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      }
    })();
  }, [offer]);

  (async () => {
    if (canDeleteAttempt.current && userHasAppliedToOffer) {
      canDeleteAttempt.current = false;

      await OfferApplicationService.dismissApplicationAttempt(offerId);

      refreshUserData({
        attempt: null
      });
    }
  })();

  if (
    router.query.onboarding === 'true' &&
    alreadyShowInSessionMissingDataModal
  ) {
    return null;
  }

  return (
    <ReactModal isOpen={true} contentLabel={getModalTitle()}>
      <Modal
        title={getModalTitle()}
        blockUI={isSubmittingNo || isSubmittingYes}
      >
        {!uiOffer && isLoading && (
          <StyledContainer>
            <Spinner />
          </StyledContainer>
        )}

        {!uiOffer && !isLoading && (
          <OfferApplyModalPositionNonExistent
            handleNoClick={handleNoClick}
            applyModalClose={applyModalClose}
          />
        )}

        {uiOffer && !isLoading && (
          <>
            {userCanApplyToCurrentOffer && userCanApplyToOffers && (
              <OfferApplyModalAplicationComplete
                uiOffer={uiOffer}
                applyModalIsOpen={applyModalIsOpen}
                handleNoClick={handleNoClick}
                handleYesClick={handleYesClick}
                isSubmittingYes={isSubmittingYes}
                isSubmittingNo={isSubmittingNo}
              />
            )}

            {userCanApplyToCurrentOffer &&
              !userCanApplyToOffers &&
              applicationAttempt && (
                <OfferApplyModalPositionOpenMissingData
                  uiOffer={uiOffer}
                  applyModalIsOpen={applyModalIsOpen}
                  applyModalClose={applyModalClose}
                  handleNoClick={handleNoClick}
                  alreadyShowMissingDataModal={alreadyShowMissingDataModal}
                  setAlreadyShowMissingDataModal={
                    setAlreadyShowMissingDataModal
                  }
                  alreadyShowInSessionMissingDataModal={
                    alreadyShowInSessionMissingDataModal
                  }
                  setAlreadyShowInSessionMissingDataModal={
                    setAlreadyShowInSessionMissingDataModal
                  }
                  handleYesClick={handleYesClick}
                  isSubmittingYes={isSubmittingYes}
                  isSubmittingNo={isSubmittingNo}
                />
              )}

            {!userCanApplyToCurrentOffer && !userCanApplyToOffers && (
              <OfferApplyModalPositionCloseMissingData
                uiOffer={uiOffer}
                applyModalIsOpen={applyModalIsOpen}
                applyModalClose={applyModalClose}
                isSubmittingYes={isSubmittingYes}
                isSubmittingNo={isSubmittingNo}
                offerId={offerId}
              />
            )}

            {!userCanApplyToCurrentOffer && userCanApplyToOffers && (
              <>
                <OfferApplyModalPositionClose
                  uiOffer={uiOffer}
                  applyModalIsOpen={applyModalIsOpen}
                  applyModalClose={applyModalClose}
                  isSubmittingYes={isSubmittingYes}
                  isSubmittingNo={isSubmittingNo}
                  offerId={offerId}
                />
              </>
            )}
          </>
        )}
      </Modal>
    </ReactModal>
  );
};

export default withTranslation(['apply-modal'])(OfferApplyModal);
