import {
  ManfrediteResponse,
  MediaSummary as MediaSummaryAPI
} from '@asgard/api-common';
import {
  Fact,
  Links,
  Location,
  Manfredite,
  ManfrediteSchema,
  MediaSummary,
  ProfilePreferences,
  Salary,
  SoftSkillV2,
  TechRole,
  TechSummary,
  WorkplacePreferences,
  YearsOfExperience
} from 'model';
import { TESTING_TAGS_PREFIX } from './tag/service.tag.constants';

// * These roles are considered "dev roles"
// * Defined here: https://getmanfred.atlassian.net/wiki/spaces/ASG/pages/3408396341/Talent+Engine+-+Tags+de+perfiles#Una-vez-que-un-usuario-tenga-perfil-en-Asgard%3A
const MANFREDITE_DEV_ROLES = [3, 8, 9, 10, 7, 11, 6, 1, 133, 2, 18, 20];

export function normalizeToManfredite(
  response: ManfrediteResponse
): Manfredite {
  const manfredite = {
    manfrediteId: response.manfrediteId,
    email: response.email,
    firstName: response.firstName,
    lastName: response.lastName,
    whereDoYouLive: normalizeWhereDoYouLive(response),
    telephone: response.telephone,
    headline: response.headline,
    introduction: response.introduction,
    softSkills: normalizeSoftSkills(response),
    goodPractices: response.goodPractices,
    facts: normalizeFacts(response),
    links: normalizeLinks(response),
    yearsOfExperience: normalizeYearsOfExperience(response),
    desiredRoles: normalizeDesiredRoles(response),
    desiredSalary: normalizeDesiredSalary(response),
    desiredSalaryDescription: response.desiredSalaryDescription,
    currentSalary: response.currentSalary,
    desiredJobDescription: response.desiredJobDescription,
    likedTechs: normalizeLikedTechs(response),
    dislikedTechs: normalizeDislikedTechs(response),
    workplacePreferences: normalizeWorkplacePreferences(response),
    profilePreferences: normalizeProfilePreferences(response),
    cvFile: normalizeMediaSummary(response.cvFile),
    avatar: normalizeMediaSummary(response.avatar),
    availabilityStatus: response.availabilityStatus || 'NOT AVAILABLE',
    preferredLanguage: response.preferredLanguage,
    isNewsletterActive: response.isNewsletterActive || false,
    hasOnboardingComplete: response.hasOnboardingComplete || false,
    isInterestedOnWorkAtManfred: response.isInterestedOnWorkAtManfred || false,
    techs: response.techs || [],
    hasSomeDevRole: response.desiredRoles.some((role) =>
      MANFREDITE_DEV_ROLES.includes(role.id)
    ),
    testingTags: response.tags.filter(({ type }) =>
      type.startsWith(TESTING_TAGS_PREFIX)
    ),
    contractTypes: response.contractTypes,
    isLegacy: response.isLegacy,
    offers: {
      applications: response.offers.applications,
      applicationAttempt: response.offers.applicationAttempt
        ? {
            offerId: response.offers.applicationAttempt.offerId,
            position: response.offers.applicationAttempt.position,
            slug: response.offers.applicationAttempt.slug,
            companyName: response.offers.applicationAttempt.companyName
          }
        : undefined
    }
  };

  const result = ManfrediteSchema.safeParse(manfredite);

  if (result.success) {
    return result.data;
  }

  console.error(
    `Zod validation error in manfrediteId: ${manfredite.manfrediteId} - ${result.error}`
  );
  return manfredite as Manfredite;
}

function normalizeWhereDoYouLive(
  response: ManfrediteResponse
): Location | undefined {
  return response.whereDoYouLive
    ? {
        placeId: response.whereDoYouLive.placeId,
        displayName: response.whereDoYouLive.displayName
      }
    : undefined;
}

function normalizeSoftSkills(response: ManfrediteResponse): SoftSkillV2[] {
  return (
    response.softSkills?.map(({ id, name }) => ({
      id,
      name
    })) || []
  );
}

function normalizeFacts(response: ManfrediteResponse): Fact[] {
  return (
    response.facts?.map(({ title, description }, index) => ({
      id: index + 1,
      title,
      description
    })) || []
  );
}

function normalizeLinks(response: ManfrediteResponse): Links {
  return {
    linkedin: response.links.linkedin,
    github: response.links.github,
    stackoverflow: response.links.stackoverflow,
    twitter: response.links.twitter,
    others:
      response.links.others?.map(({ url, desc }) => ({ url, title: desc })) ||
      []
  };
}

function normalizeYearsOfExperience(
  response: ManfrediteResponse
): YearsOfExperience | undefined {
  return response.yearsOfExperience
    ? {
        id: response.yearsOfExperience.id,
        min: response.yearsOfExperience.min,
        max: response.yearsOfExperience.max
      }
    : undefined;
}

function normalizeDesiredRoles(response: ManfrediteResponse): TechRole[] {
  return response.desiredRoles?.map(({ id, name }) => ({ id, name })) || [];
}

function normalizeDesiredSalary(
  response: ManfrediteResponse
): Salary | undefined {
  return response.desiredSalary
    ? {
        id: response.desiredSalary.id,
        min: response.desiredSalary.min,
        max: response.desiredSalary.max,
        currency: response.desiredSalary.currency
      }
    : undefined;
}

function normalizeLikedTechs(response: ManfrediteResponse): TechSummary[] {
  return response.likedTechs?.map(({ id, name }) => ({ id, name })) || [];
}

function normalizeDislikedTechs(response: ManfrediteResponse): TechSummary[] {
  return response.dislikedTechs?.map(({ id, name }) => ({ id, name })) || [];
}

function normalizeWorkplacePreferences(
  response: ManfrediteResponse
): WorkplacePreferences {
  return {
    isRemote: response.workplacePreferences.isRemote,
    isPartiallyRemote: response.workplacePreferences.isPartiallyRemote,
    isFaceToFace: response.workplacePreferences.isFaceToFace,
    isAnywhere: response.workplacePreferences.isAnywhere,
    desiredLocations:
      response.workplacePreferences.desiredLocations?.map(
        ({ placeId, displayName }) => ({ placeId, displayName })
      ) || [],
    comments: response.workplacePreferences.comments
  };
}

function normalizeProfilePreferences(
  response: ManfrediteResponse
): ProfilePreferences {
  return {
    isPublic: response.profilePreferences.isPublic,
    isIndexable: response.profilePreferences.isIndexable,
    slug: response.profilePreferences.slug,
    defaultView: response.profilePreferences.defaultView,
    isEmailVisible: response.profilePreferences.isEmailVisible,
    isPhoneNumberVisible: response.profilePreferences.isPhoneNumberVisible
  };
}
function normalizeMediaSummary(
  media?: MediaSummaryAPI
): MediaSummary | undefined {
  return media
    ? {
        id: media.id,
        title: media.title,
        filename: media.filename,
        url: media.url,
        alt: media.alt
      }
    : undefined;
}
