import { OfferResponse, OfferResumeResponse } from '@asgard/api-common';
import { MultilingualLanguages } from '@asgard/common-dto';
import { OffersListFilter } from 'model/model-offers-list-filter';
import { WebOffer } from 'model/model.web-offer';
import { WebOfferSummary } from 'model/model.web-offer-summary';
import { getApiPathUrl } from 'utils/api';
import * as fetch from 'utils/fetch';
import { getUrlParamsForAPICallFromJobOffersFilter } from 'utils/job-offers-filter';
import {
  normalizeToWebOffer,
  normalizeToWebOfferSummaryList
} from './service.web-offer.normalizer';

const PATH = '/v2/public/offers';

const defaultListFilter: OffersListFilter = {
  onlyActive: true
};

/**
 * Service to interact with Manfred's web offers
 */
export const ServiceWebOffer = {
  /**
   * Get the list of Manfred's web offers
   *
   * @param lang locale used to get the offers
   *
   * @returns the list of offers
   */
  getAllSummary: async (
    filter: OffersListFilter = defaultListFilter,
    lang: MultilingualLanguages,
    token?: string
  ): Promise<WebOfferSummary[]> => {
    const queryParams = getUrlParamsForAPICallFromJobOffersFilter(filter, lang);

    const url = getApiPathUrl(PATH, token);
    const res = await fetch.get<OfferResumeResponse[]>(`${url}?${queryParams}`);

    return normalizeToWebOfferSummaryList(res, lang);
  },

  /**
   * Get a Manfred's web offer by ID
   *
   * @param id offer id
   * @param lang locale used to get the offers
   *
   * @returns the list of offer
   */
  getById: async (
    id: number,
    lang: MultilingualLanguages,
    token?: string
  ): Promise<WebOffer> => {
    if (isNaN(id)) {
      throw new Error('Invalid offer id');
    }

    const url = getApiPathUrl(`${PATH}/${id}`, token);
    const res = await fetch.get<OfferResponse>(`${url}?lang=${lang}`);

    return normalizeToWebOffer(res, lang);
  },

  /**
   * Get a Manfred's web offer by preview ID
   *
   * @param previewId offer preview id
   * @param id offer id
   * @param lang locale used to get the offers
   *
   * @returns the list of offer
   */
  getByPreviewId: async (
    previewId: string,
    id: string,
    lang: MultilingualLanguages,
    token?: string
  ): Promise<WebOffer> => {
    const url = getApiPathUrl(`${PATH}/${id}/preview/${previewId}`, token);
    const res = await fetch.get<OfferResponse>(`${url}?lang=${lang}`);

    return normalizeToWebOffer(res, lang);
  }
};
