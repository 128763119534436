import { OfferFilterQueryRequest } from '@asgard/api-common';
import { MultilingualLanguages } from '@asgard/common-dto';
import { LanguageLevel } from 'model';
import { OffersListFilter } from 'model/model-offers-list-filter';
import { encode, ParsedUrlQuery } from 'querystring';
import { APP_URLS, getAppUrl } from './app-urls';
import {
  OFFER_FILTER_QUERY_PARAM_CURRENCY,
  OFFER_FILTER_QUERY_PARAM_LOCATIONS,
  OFFER_FILTER_QUERY_PARAM_MIN_LANGUAGES,
  OFFER_FILTER_QUERY_PARAM_MIN_SALARY,
  OFFER_FILTER_QUERY_PARAM_OFFER_LANGUAGE,
  OFFER_FILTER_QUERY_PARAM_ONLY_ACTIVE,
  OFFER_FILTER_QUERY_PARAM_REMOTE_OPTIONS,
  OFFER_FILTER_QUERY_PARAM_TECH_ROLES,
  OFFER_FILTER_QUERY_PARAM_TECHS,
  OFFER_FILTER_QUERY_PARAM_TEXT
} from './job-offers-filter.constants';
import { SetUrlQueryParamsFromJobOffersFilter } from './job-offers-filter.types';
import { getLocale } from './localize.utils';

export const setUrlQueryParamsFromJobOffersFilter: SetUrlQueryParamsFromJobOffersFilter =
  async ({ filter, router }) => {
    const params = getUrlParamsFromJobOffersFilter(
      filter,
      getLocale(router.locale),
      // TODO: Extract to constants
      ['unsuscribe-alert', 'open-alerts']
    );

    if (params) {
      await router.replace(
        {
          pathname: getAppUrl(APP_URLS.jobOffers, router.locale),
          query: params.toString()
        },
        undefined,
        { shallow: true }
      );
    }
  };

export const getUrlParamsFromJobOffersFilter = (
  filter: OffersListFilter,
  lang: MultilingualLanguages,
  excludedQueryParams?: string[]
): URLSearchParams => {
  const params = new URLSearchParams(
    typeof window !== 'undefined' ? window.location.search : ''
  );

  if (excludedQueryParams) {
    excludedQueryParams.forEach((key) => {
      params.delete(key);
    });
  }

  params.set(
    OFFER_FILTER_QUERY_PARAM_ONLY_ACTIVE,
    filter.onlyActive.toString()
  );

  if (filter.minSalary) {
    params.set(OFFER_FILTER_QUERY_PARAM_MIN_SALARY, filter.minSalary);
  } else {
    params.delete(OFFER_FILTER_QUERY_PARAM_MIN_SALARY);
  }

  if (filter.minLanguages && filter.minLanguages.length > 0) {
    params.set(
      OFFER_FILTER_QUERY_PARAM_MIN_LANGUAGES,
      filter.minLanguages
        .filter(
          (minLang) =>
            !!minLang.language.value && minLang.level.value !== 'not-defined'
        )
        .map((minLang) => `${minLang.language.value}:${minLang.level.value}`)
        .join('|')
    );
  } else {
    params.delete(OFFER_FILTER_QUERY_PARAM_MIN_LANGUAGES);
  }

  if (filter.text) {
    params.set(OFFER_FILTER_QUERY_PARAM_TEXT, filter.text);
  } else {
    params.delete(OFFER_FILTER_QUERY_PARAM_TEXT);
  }

  if (filter.locations && filter.locations.length > 0) {
    params.set(
      OFFER_FILTER_QUERY_PARAM_LOCATIONS,
      filter.locations.map((c) => `${c?.placeId}:${c?.displayName}`).join('|')
    );
  } else {
    params.delete(OFFER_FILTER_QUERY_PARAM_LOCATIONS);
  }

  if (filter.techs && filter.techs.length > 0) {
    params.set(OFFER_FILTER_QUERY_PARAM_TECHS, filter.techs.join(','));
  } else {
    params.delete(OFFER_FILTER_QUERY_PARAM_TECHS);
  }

  if (filter.techRoles && filter.techRoles.length > 0) {
    params.set(OFFER_FILTER_QUERY_PARAM_TECH_ROLES, filter.techRoles.join(','));
  } else {
    params.delete(OFFER_FILTER_QUERY_PARAM_TECH_ROLES);
  }

  if (filter.remoteOptions && filter.remoteOptions.length > 0) {
    params.set(
      OFFER_FILTER_QUERY_PARAM_REMOTE_OPTIONS,
      filter.remoteOptions.map((c) => c).join(',')
    );
  } else {
    params.delete(OFFER_FILTER_QUERY_PARAM_REMOTE_OPTIONS);
  }

  if (filter.offerLanguage) {
    params.set(OFFER_FILTER_QUERY_PARAM_OFFER_LANGUAGE, filter.offerLanguage);
  } else {
    params.delete(OFFER_FILTER_QUERY_PARAM_OFFER_LANGUAGE);
  }

  params.set(OFFER_FILTER_QUERY_PARAM_CURRENCY, filter.currency || '€');

  return params;
};

export const getUrlParamsForAPICallFromJobOffersFilter = (
  filter: OffersListFilter,
  locale: MultilingualLanguages = 'ES'
): URLSearchParams => {
  const params = new URLSearchParams();

  const req: OfferFilterQueryRequest = {
    lang: locale.toUpperCase() as MultilingualLanguages,
    onlyActive: filter.onlyActive,
    roles: filter.techRoles,
    techs: filter.techs,
    text: filter.text,
    currency: filter.currency,
    minSalaryInK: filter.minSalary ? +filter.minSalary : undefined,
    remoteOptions:
      filter.remoteOptions as OfferFilterQueryRequest['remoteOptions'],
    locations: filter.locations?.map((c) => c.placeId),
    offerLanguage: filter.offerLanguage as MultilingualLanguages
  };

  Object.entries(req).forEach(([key, value]) => {
    if (value) {
      params.set(key, value.toString());
    }
  });

  if (filter.techRoles && filter.techRoles?.length <= 0) {
    params.delete('roles');
  }

  if (filter.techs && filter.techs?.length <= 0) {
    params.delete('techs');
  }

  if (filter.locations && filter.locations?.length <= 0) {
    params.delete('locations');
  }

  if (filter.remoteOptions && filter.remoteOptions?.length <= 0) {
    params.delete('remoteOptions');
  }

  return params;
};

export function getFilterFromParsedUrlQuery(
  params: ParsedUrlQuery
): OffersListFilter {
  const urlQueryString = encode(params);
  const searchParams = new URLSearchParams(urlQueryString);

  return getFilterFromUrlParams(searchParams);
}

export function getFilterFromUrlParams(
  params: URLSearchParams
): OffersListFilter {
  const locationsParamValue =
    params.get(OFFER_FILTER_QUERY_PARAM_LOCATIONS) || '';
  const techsParamValue = params.get(OFFER_FILTER_QUERY_PARAM_TECHS) || '';
  const techRolesParamValue =
    params.get(OFFER_FILTER_QUERY_PARAM_TECH_ROLES) || '';
  const minLanguagesParamValue =
    params.get(OFFER_FILTER_QUERY_PARAM_MIN_LANGUAGES) || '';

  return {
    onlyActive: params.has(OFFER_FILTER_QUERY_PARAM_ONLY_ACTIVE)
      ? params.get(OFFER_FILTER_QUERY_PARAM_ONLY_ACTIVE) === 'true'
      : true,
    remoteOptions:
      params.get(OFFER_FILTER_QUERY_PARAM_REMOTE_OPTIONS)?.split(',') ||
      undefined,
    minSalary: params.get(OFFER_FILTER_QUERY_PARAM_MIN_SALARY) || undefined,
    minLanguages:
      minLanguagesParamValue === ''
        ? undefined
        : minLanguagesParamValue.split('|').map((minLang) => ({
            id: +minLang.split(':')[0],
            language: {
              value: +minLang.split(':')[0],
              label: minLang.split(':')[0]
            },
            level: {
              value: minLang.split(':')[1] as LanguageLevel,
              label: minLang.split(':')[1]
            }
          })),
    text: params.get(OFFER_FILTER_QUERY_PARAM_TEXT) || undefined,
    techs:
      techsParamValue === ''
        ? undefined
        : techsParamValue
            .split(',')
            .filter(Boolean)
            .map((value) => +value),
    techRoles:
      techRolesParamValue === ''
        ? undefined
        : techRolesParamValue
            .split(',')
            .filter(Boolean)
            .map((value) => +value),
    locations:
      locationsParamValue === ''
        ? undefined
        : locationsParamValue.split('|').map((city) => ({
            placeId: city.split(':')[0],
            displayName: city.split(':')[1]
          })),
    currency: params.get(OFFER_FILTER_QUERY_PARAM_CURRENCY) || undefined,
    offerLanguage:
      params.get(OFFER_FILTER_QUERY_PARAM_OFFER_LANGUAGE) || undefined
  };
}
